<template>
  <a-card class="page-container">
    <a-row :gutter="24">
      <a-col :span="6">
        <a-card>
          <a-tabs v-model="timeType">
            <a-tab-pane tab="周" :key="2" />
            <a-tab-pane tab="月" :key="3" />
          </a-tabs> 
          <summary-time-picker
            :type="timeType === 2 ? 'week' : 'month'"
            @select="handleTimeSelect"
          />
        </a-card>
      </a-col>

      <a-col :span="18">
        <a-tabs v-model="activeTab" @change="handleTabsChange">
          <a-tab-pane
            v-for="item in tabList"
            :key="item.value"
            :tab="item.label"
          />
        </a-tabs>

        <search-form
          :form="searchForm"
          :principal-list="principalList"
          :area-list="areaList"
          @principal-change="handlePrincipalChange"
          @search="handleSearch"
          @reset="handleReset"
        />

        <div class="oper-bar">
          <a-space>
            <a-button
              type="primary"
              :loading="exporting"
              @click="handleExport(1)"
            >
              全部数据导出
            </a-button>
            <a-button
              type="primary"
              :loading="exporting2"
              @click="handleExport(2)"
            >
              导出当前搜索条件数据
            </a-button>
          </a-space>
        </div>

        <data-table
          :loading="loading"
          :data="tableData"
          :pagination="pagination"
          @pagination-change="handlePaginationChange"
          @action-click="handleActionClick"
        />
      </a-col>
    </a-row>
  </a-card>
</template>

<script>
import SummaryTimePicker from './components/SummaryTimePicker'
import SearchForm from './components/SearchForm'
import DataTable from './components/DataTable'
import { downloadExcel } from '@/utils'
import activityApi from '@/api/activity'

export default {
  name: 'actContentList',
  components: {
    SummaryTimePicker,
    SearchForm,
    DataTable
  },
  data () {
    return {
      timeType: 2, // 2: week, 3: nmonth
      timeStart: this.$moment().startOf('week').format('YYYY-MM-DD 00:00:00'),
      timeEnd: this.$moment().endOf('week').format('YYYY-MM-DD 23:59:59'),
      activeTab: '',
      tabList: [
        { label: '全部', value: '' },
        { label: '按时提交', value: 2 },
        { label: '未提交', value: 1 },
        { label: '延迟提交', value: 3 }
      ],
      searchForm: {
        principalId: '',
        areaId: '',
        userName: '',
        dealerName: ''
      },
      principalList: [],
      areaList: [
        { label: '全部', value: '' }
      ],
      loading: false,
      tableData: [],
      pagination: {
        page: 1,
        size: 10,
        total: 0
      },
      exporting: false,
      exporting2: false
    }
  },
  created () {
    this.fetchWeekListLeader()
    this.fetchPrincipalList()
  },
  methods: {
    handleTimeSelect (val) {
      if (this.timeType === 2) {
        this.timeStart = `${val.start} 00:00:00`
        this.timeEnd = `${val.end} 23:59:59`
        this.fetchWeekListLeader()
      }

      if (this.timeType === 3) {
        const daysInMonth = this.$moment(val).daysInMonth()

        this.timeStart = `${val}-01 00:00:00` 
        this.timeEnd = `${val}-${daysInMonth} 23:59:59`
        this.fetchMonthListLeader()
      }
    },
    //
    handleTabsChange () {
      this.pagination.page = 1
      this.timeType === 2 && this.fetchWeekListLeader()
      this.timeType === 3 && this.fetchMonthListLeader()
    },
    // 处理查询
    handleSearch () {
      this.pagination.page = 1
      this.timeType === 2 && this.fetchWeekListLeader()
      this.timeType === 3 && this.fetchMonthListLeader()
    },
    // 处理重置
    handleReset () {
      this.pagination.page = 1
      this.timeType === 2 && this.fetchWeekListLeader()
      this.timeType === 3 && this.fetchMonthListLeader()
    },
    // 处理分页改变
    handlePaginationChange (page, size) {
      this.pagination.page = page
      this.pagination.size = size
      this.timeType === 2 && this.fetchWeekListLeader()
      this.timeType === 3 && this.fetchMonthListLeader()
    },
    // 获取作品周列表
    async fetchWeekListLeader () {
      this.loading = true
      try {
        const params = {
          activityId: this.$route.query.activityId,
          submitTimeStart: this.timeStart,
          submitTimeEnd: this.timeEnd,
          submitStatus: this.activeTab,
          ...this.searchForm,
          ...this.pagination
        }
        const { code, data, message } = await activityApi.fetchWeekListLeader(params)
        
        if (code === 200) {
          this.tableData = data.list
          this.pagination.total = data.total
        } else {
          this.$message.error(message)
        }

        this.loading = false
      } catch (e) {
        console.log(e)
        this.loading = false
      }
    },
    // 获取作品月列表
    async fetchMonthListLeader () {
      this.loading = true
      try {
        const params = {
          activityId: this.$route.query.activityId,
          submitTimeStart: this.timeStart,
          submitTimeEnd: this.timeEnd,
          submitStatus: this.activeTab,
          ...this.searchForm,
          ...this.pagination
        }
        const { code, data, message } = await activityApi.fetchMonthListLeader(params)

        if (code === 200) {
          this.tableData = data.list
          this.pagination.total = data.total
        } else {
          this.$message.error(message)
        }

        this.loading = false
      } catch (e) {
        console.log(e)
        this.loading = false
      }
    },
    // 获取品牌（主体）列表
    async fetchPrincipalList () {
      try {
        const res = await this.$api.core.TrainingList.getPrincipals()

        if (Array.isArray(res)) {
          this.principalList = [
            { label: '全部', value: '' },
            ...res.map(item => {
              return { label: item.principal_name, value: item.id }
            })
          ]
        } else {
          this.principalList = []
          console.log('获取主体失败')
        }
      } catch (e) {
        console.log(e)
      }
    },
    // 处理品牌（主体）改变
    handlePrincipalChange (val) {
      this.searchForm.areaId = ''
      this.fetchAreaList(val)
    },
    // 获取区域列表
    async fetchAreaList (principalId) {
      try {
        const params = {
          principal_id: principalId  
        }
        const { code, data, message } = await activityApi.fetchAreaList(params)

        if (code === 200) {
          this.areaList = [
            { label: '全部', value: '' },
            ...data.map(item => {
              return { label: item.name, value: item.id }
            })
          ]
        } else {
          this.$message.error(message)
        }
      } catch (e) {
        console.log(e)
      }
    },
    // 处理导出
    handleExport (type) {
      if (this.timeType === 2 && type === 1) { // 全部
        this.exportWeekListLeader(type, {
          activityId: this.$route.query.activityId,
          submitTimeStart: this.timeStart,
          submitTimeEnd: this.timeEnd,
          submitStatus: this.activeTab
        })
      }

      if (this.timeType === 2 && type === 2) { // 部分 
        this.exportWeekListLeader(type, {
          activityId: this.$route.query.activityId,
          submitTimeStart: this.timeStart,
          submitTimeEnd: this.timeEnd,
          submitStatus: this.activeTab,
          ...this.searchForm,
        })
      }

      if (this.timeType === 3 && type === 1) { // 全部
        this.exportMonthListLeader(type, {
          activityId: this.$route.query.activityId,
          submitTimeStart: this.timeStart,
          submitTimeEnd: this.timeEnd,
          submitStatus: this.activeTab
        })  
      }

      if (this.timeType === 3 && type === 2) { // 部分
        this.exportMonthListLeader(type, {
          activityId: this.$route.query.activityId,
          submitTimeStart: this.timeStart,
          submitTimeEnd: this.timeEnd,
          submitStatus: this.activeTab,
          ...this.searchForm
        })  
      }
    },
    //
    async exportWeekListLeader (type, params) {
      type === 1 && (this.exporting = true)
      type === 2 && (this.exporting2 = true)
      try {
        const res = await activityApi.exportWeekListLeader(params)

        downloadExcel(res, '作品动态数据')
        type === 1 && (this.exporting = false)
        type === 2 && (this.exporting2 = false)
      } catch (e) {
        console.log(e)
        type === 1 && (this.exporting = false)
        type === 2 && (this.exporting2 = false)
      }
    },
    //
    async exportMonthListLeader (type, params) {
      type === 1 && (this.exporting = true)
      type === 2 && (this.exporting2 = true)
      try {
        const res = await activityApi.exportMonthListLeader(params)

        downloadExcel(res, '作品动态数据')
        type === 1 && (this.exporting = false)
        type === 2 && (this.exporting2 = false)
      } catch (e) {
        console.log(e)
        type === 1 && (this.exporting = false)
        type === 2 && (this.exporting2 = false)
      }
    },
    // 处理操作
    handleActionClick (type, record) {
      let queryParams = {
        activityId: this.$route.query.activityId,
        timeType: this.timeType,
        principalName: record.principalName,
        areaName: record.area,
        dealerId: record.dealerId,
        dealerName: record.dealerName,
        id: record.id,
        userId: record.userId,
        ctime: record.ctime,
        submitStatus: record.submitStatus
      }

      this.$router.push({
        name: 'actManage:contentDetail',
        query: queryParams
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.page-container {
  padding: 0 !important;
}

.oper-bar {
  margin: 10px 0 0;
  text-align: right;
}

.data-table {
  margin: 10px 0 0;
}
</style>
