<template>
  <div class="summary-time-picker">
    <div class="picker-week" v-if="type === 'week'">
      <div class="picker-week__hd">
        <div>{{ currMonth | formatDate('YYYY年MM月') }}</div>
        <div>
          <a-icon type="up" @click="lastMonth" />
          <a-icon type="down" @click="nextMonth" />
        </div>
      </div>
      <div class="picker-week__bd">
        <div
          class="picker-week__bd-week"
          v-for="(item, index) in weeks"
          :key="index"
          :class="{
            'is-selected': item.start.isSame(selectedWeek.start, 'day')
          }"
          @click="handleSelect(item)"
        >
          <span>第{{ index + 1 }}周</span>  
          <span>
            {{ item.start | formatDate('MM/DD') }} ~ {{ item.end | formatDate('MM/DD') }}
          </span>
        </div>
      </div>
    </div>

    <div class="picker-month" v-if="type === 'month'">
      <div class="picker-month__hd">
        <a-icon type="left" @click="prevYear" />
        <div>{{ currYear | formatDate('YYYY') }}</div>
        <a-icon type="right" @click="nextYear" />
      </div>
      <div class="picker-month__bd">
        <div
          class="picker-month__bd-month"
          v-for="(item, index) in months"
          :key="index"
          :class="{
            'is-selected': item.isSame(selectedMonth, 'month')
          }"
          @click="handleSelect(item)"
        >
          {{ item | formatDate('M月') }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment'

export default {
  name: 'SummaryTimePicker',
  props: {
    type: {
      type: String,
      default: 'week' // week, month
    }
  },
  data () {
    return {
      currMonth: moment(),
      weeks: [],
      selectedWeek: {
        start: moment().startOf('week'),
        end: moment().endOf('week')
      }, 
      currYear: moment(),
      selectedMonth: moment(),
      months: []
    }
  },
  created () {
    this.init()
  },
  watch: {
    type (newVal, oldVal) {
      this.init()

      if (this.type === 'week') {
        this.$emit('select', {
          start: this.selectedWeek.start.format('YYYY-MM-DD'),
          end: this.selectedWeek.end.format('YYYY-MM-DD')
        })
      }

      if (this.type === 'month') {
        this.$emit('select', this.selectedMonth.format('YYYY-MM'))
      }
    }
  },
  filters: {
    formatDate (val, format) {
      return moment(val).format(format)
    }
  },
  methods: {
    init () {
      if (this.type === 'week') {
        this.currMonth = moment()
        this.createWeeks()
      }

      if (this.type === 'month') {
        this.currYear = moment()
        this.createMonths()
      }
    },
    lastMonth () {
      this.currMonth = moment(this.currMonth).subtract(1, 'months')
      this.createWeeks()
    },
    nextMonth () {
      this.currMonth = moment(this.currMonth).add(1, 'months')
      this.createWeeks()
    },
    createWeeks () {
      const lastDay = moment(this.currMonth).endOf('month')
      const weekNum = Math.ceil((lastDay.date() + 6 - (lastDay.day() === 0 ? 7 : lastDay.day())) / 7)
      let weeks = []
      
      for (let i = weekNum; i > 0; i--) {
        weeks.push({
          start: moment(lastDay).subtract(weekNum - i, 'weeks').startOf('week'),
          end: moment(lastDay).subtract(weekNum - i, 'weeks').endOf('week')
        })
      }

      this.weeks = weeks.reverse()
    },
    prevYear () {
      this.currYear = this.currYear.subtract(1, 'years')
      this.createMonths()
    },
    nextYear () {
      this.currYear = this.currYear.add(1, 'years')
      this.createMonths()
    },
    createMonths () {
      let months = []

      for (let i = 1; i <= 12; i++) {
        months.push(
          moment(`${this.currYear.format('YYYY')}-${i < 10 ? '0' + i : i}`)
        )
      }

      this.months = months
    },
    handleSelect (val) {
      if (this.type === 'week') {
        this.selectedWeek = { ...val }
        this.$emit('select', {
          start: val.start.format('YYYY-MM-DD'),
          end: val.end.format('YYYY-MM-DD')
        })
      }

      if (this.type === 'month') {
        this.selectedMonth = val
        this.$emit('select', val.format('YYYY-MM'))
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.picker-week {
  &__hd {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 0 10px;
    font-weight: bold;
  }

  &__bd {
    &-week {
      display: flex;
      align-items: center;
      margin: 6px 0;
      padding: 0 20px;
      height: 36px;
      border-radius: 6px;
      cursor: pointer;

      span {
        margin: 0 10px 0 0;
      }

      &.is-selected {
        color: #fff;
        background-color: #1890ff;  
      }
    }
  }
}

.picker-month {
  &__hd {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
    color: #141c28;
    font-weight: 600;
  }

  &__bd {
    display: flex;
    flex-wrap: wrap;
    padding: 20px;

    &-month {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 10px 0;
      width: 33.33%;
      height: 28px;
      border-radius: 6px;
      cursor: pointer;

      &.is-selected {
        color: #fff;
        background-color: #1890ff;  
      }
    }
  }
}
</style>
